<template>
  <b-card>
    <validation-observer ref="updatePaymentMethodForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        key="updatePaymentMethodForm"
        @submit="submit"
        :form="$refs.updatePaymentMethodForm"
        :disabled="invalid"
      >
        <b-row v-for="(data, idx) in accounts" :key="`account-${idx}`">
          <b-col md="3">
            <b-form-group :label="$t('field.bank')" label-for="bank">
              <validation-provider
                vid="bank"
                #default="{ errors }"
                :name="$t('field.bank')"
                rules="required|max:100"
              >
                <b-form-input
                  id="bank"
                  name="bank"
                  v-model="data.bankName"
                  :placeholder="$t('field.bank')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('field.accountNumber')"
              label-for="accountNumber"
            >
              <validation-provider
                vid="accountNumber"
                #default="{ errors }"
                :name="$t('field.accountNumber')"
                rules="required|max:100"
              >
                <b-form-input
                  id="accountNumber"
                  name="accountNumber"
                  v-model="data.accountNumber"
                  :placeholder="$t('field.accountNumber')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('field.accountHolder')"
              label-for="accountHolder"
            >
              <validation-provider
                vid="accountHolder"
                #default="{ errors }"
                :name="$t('field.accountHolder')"
                rules="required|max:100"
              >
                <b-form-input
                  id="accountHolder"
                  name="accountHolder"
                  v-model="data.accountHolder"
                  :placeholder="$t('field.accountHolder')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" align-self="center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              type="button"
              variant="danger"
              class="mt-1"
              @click="removeAccount(idx)"
            >
              {{ $t("button.remove") }}
            </b-button>
          </b-col>
          <b-col cols="12" v-if="idx != accounts.length - 1">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <!-- buttons -->
          <b-col cols="12" class="text-right">
            <b-button
              block
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mt-1"
              @click="addNewAccount"
            >
              {{ $t("button.addNewAccount") }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
        <b-row>
          <!-- buttons -->
          <b-col cols="12" class="text-right">
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $t("button.saveChanges") }}
            </n-button-loading>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";
import NCurrencyInput from "@/components/NCurrencyInput";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";

const DepositSettingRepository = Repository.get("depositSetting");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    NButtonLoading,
    NCurrencyInput,
    NFormConfirmation,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accounts: [],
      loading: false,
    };
  },
  created() {
    this.show();
  },
  methods: {
    addNewAccount() {
      this.accounts.push({
        bank_name: null,
        accountNumber: null,
        accountHolder: null,
      });
    },
    removeAccount(idx) {
      this.accounts.splice(idx, 1);
    },
    show() {
      DepositSettingRepository.show().then((response) => {
        this.accounts = [
          ...response.data.data,
        ];
      });
    },
    submit() {
      this.$refs.updatePaymentMethodForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            paymentMethods: this.accounts,
          };
          DepositSettingRepository.update(data)
            .then((response) => {
              this.accounts = [...response.data.data]
            })
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updatePaymentMethodForm.setErrors(
                  error.response?.data?.message
                );
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
