<template>
  <b-card>
    <validation-observer ref="updateExchangeRateForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        key="updateExchangeRateForm"
        @submit="submit"
        :form="$refs.updateExchangeRateForm"
        :disabled="invalid"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('field.exchangeRatePerDollar')"
              label-for="exchange-rate"
            >
              <validation-provider
                vid="exchangeRatePerDollar"
                #default="{ errors }"
                :name="$t('field.exchangeRatePerDollar')"
                rules="required|min_value:3500|max_value:5000"
              >
                <n-currency-input
                  id="exchange-rate"
                  class="form-control"
                  type="text"
                  name="exchange-rate"
                  v-model="data.rate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- buttons -->
          <b-col cols="12" class="text-right">
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1 mr-1"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $t("button.saveChanges") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="resetForm"
            >
              {{ $t("button.reset") }}
            </n-button-loading>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";
import NCurrencyInput from "@/components/NCurrencyInput";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";

const CurrencyRepository = Repository.get("currency");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    NButtonLoading,
    NCurrencyInput,
    NFormConfirmation,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        rate: null,
      },
      rate: null,
      loading: false,
    };
  },
  created() {
    this.show();
  },
  methods: {
    resetForm() {
      this.data = {
        rate: this.rate,
      };

      this.$nextTick(() => {
        this.$refs.updateExchangeRateForm.reset();
      });
    },
    show() {
      CurrencyRepository.show().then((response) => {
        this.data = {
          ...response.data.data,
        };
        this.rate = response.data.data.rate
      });
    },
    submit() {
      this.$refs.updateExchangeRateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          CurrencyRepository.update(this.data)
            .then((response) => {})
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updateExchangeRateForm.setErrors(
                  error.response?.data?.message
                );
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
