<template>
  <b-card>
    <validation-observer
      ref="updateProfileForm"
      #default="{ invalid }"
    >
      <!-- form -->
      <n-form-confirmation
        key="updateProfileForm"
        :form="$refs.updateProfileForm"
        :disabled="invalid"
        @submit="submit"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('field.name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                vid="name"
                :name="$t('field.name')"
                rules="required"
              >
                <b-form-input
                  v-model="data.name"
                  :placeholder="$t('field.name')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="name"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('field.phoneNumber')"
              label-for="phoneNumber"
            >
              <validation-provider
                #default="{ errors }"
                vid="name"
                :name="$t('field.phone_number')"
                rules=""
              >
                <b-form-input
                  v-model="data.phone"
                  :placeholder="$t('field.phoneNumber')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="phoneNumber"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('field.username')"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                vid="username"
                :name="$t('field.username')"
                rules="required"
              >
                <b-form-input
                  v-model="data.username"
                  :placeholder="$t('field.username')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="username"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="data.account"
            sm="6"
          >
            <b-form-group
              :label="
                $t('field.givenCredit') +
                  (data.account.ccy === 840 ? '($)' : ' (៛)')
              "
              label-for="givenCredit"
            >
              <validation-provider
                #default="{ errors }"
                vid="givenCredit"
                :name="$t('field.givenCredit')"
                rules="required"
              >
                <n-currency-input
                  :id="'givenCredit'"
                  v-model="data.account.givenCredit"
                  :name="'givenCredit'"
                  :placeholder="$t('field.givenCredit')"
                  :disabled="true"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="data.account"
            sm="6"
          >
            <b-form-group
              :label="
                $t('field.avaliableCredit') +
                  (data.account.ccy === 840 ? '($)' : ' (៛)')
              "
              label-for="avaliableCredit"
            >
              <validation-provider
                #default="{ errors }"
                vid="avaliableCredit"
                :name="$t('field.avaliableCredit')"
                rules="required"
              >
                <n-currency-input
                  :id="'avaliableCredit'"
                  v-model="data.account.avalBalance"
                  :name="'avaliableCredit'"
                  :placeholder="$t('field.avaliableCredit')"
                  :disabled="true"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="data.betSetting"
            sm="6"
          >
            <b-form-group
              :label="
                $t('field.commission') +
                  (data.betSetting.ccy === 840 ? '($)' : ' (៛)')
              "
              label-for="phoneNumber"
            >
              <validation-provider
                #default="{ errors }"
                vid="name"
                :name="$t('field.commission')"
                rules="required"
              >
                <n-currency-input
                  :id="'commission'"
                  v-model="data.betSetting.commission"
                  :name="'commission'"
                  :placeholder="$t('field.commission')"
                  :disabled="true"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="data.typeId == 5"
            sm="6"
          >
            <b-form-group
              :label="$t('field.telegramId')"
              label-for="telegramId"
            >
              <validation-provider
                #default="{ errors }"
                vid="telegramId"
                :name="$t('field.telegramId')"
                rules=""
              >
                <b-form-input
                  v-model="data.telegramChatId"
                  :placeholder="$t('field.telegramId')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="telegramId"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="data.registerUrl && data.isCashMarket"
            class="pt-2"
            sm="6"
          >
            <qr-code :text="data.registerUrl" />
            <a
              class="pt-2 d-block"
              target="_blank"
              :href="data.registerUrl"
            >
              {{ data.registerUrl }}
            </a>
          </b-col>
        </b-row>
        <!--        <div class="d-flex mt-2" v-if="data.isVendor">-->
        <!--          <feather-icon size="19" icon="SettingsIcon" />-->
        <!--          <h4 class="ml-50">{{ $t("breadcrumb.vendorSetting") }}</h4>-->
        <!--        </div>-->
        <!--        <b-row v-if="data.isVendor">-->
        <!--          <b-col sm="12">-->
        <!--            <n-button-loading-->
        <!--              type="button"-->
        <!--              variant="primary"-->
        <!--              class="mb-1"-->
        <!--              v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
        <!--              :loading="loading"-->
        <!--              v-if="data.isVendor"-->
        <!--              @submit="generateCredential"-->
        <!--            >-->
        <!--              {{ $t("button.generateCredential") }}-->
        <!--            </n-button-loading>-->
        <!--          </b-col>-->
        <!--          <b-col sm="6">-->
        <!--            <b-form-group :label="$t('field.vendorKey')" label-for="vendorKey">-->
        <!--              <validation-provider-->
        <!--                #default="{ errors }"-->
        <!--                vid="vendorKey"-->
        <!--                :name="$t('field.vendorKey')"-->
        <!--                rules="required"-->
        <!--              >-->
        <!--                <b-input-group>-->
        <!--                  <b-form-input-->
        <!--                    v-model="data.vendorKey"-->
        <!--                    :placeholder="$t('field.vendorKey')"-->
        <!--                    :class="errors.length > 0 ? 'is-invalid' : null"-->
        <!--                    name="vendorKey"-->
        <!--                    :disabled="true"-->
        <!--                  />-->
        <!--                  <template #append>-->
        <!--                    <b-button-->
        <!--                      variant="primary"-->
        <!--                      @click="copy(data.vendorKey)"-->
        <!--                      :disabled="!data.vendorKey"-->
        <!--                    >-->
        <!--                      <feather-icon icon="CopyIcon" />-->
        <!--                    </b-button>-->
        <!--                  </template>-->
        <!--                </b-input-group>-->
        <!--                <small class="text-danger">{{ errors[0] }}</small>-->
        <!--              </validation-provider>-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--          <b-col sm="6">-->
        <!--            <b-form-group-->
        <!--              :label="$t('field.vendorSecret')"-->
        <!--              label-for="vendorSecret"-->
        <!--            >-->
        <!--              <validation-provider-->
        <!--                #default="{ errors }"-->
        <!--                vid="vendorSecret"-->
        <!--                :name="$t('field.vendorSecret')"-->
        <!--                rules="required"-->
        <!--              >-->
        <!--                <b-input-group>-->
        <!--                  <b-form-input-->
        <!--                    v-model="data.vendorSecret"-->
        <!--                    :placeholder="$t('field.vendorSecret')"-->
        <!--                    :class="errors.length > 0 ? 'is-invalid' : null"-->
        <!--                    name="vendorSecret"-->
        <!--                    :disabled="true"-->
        <!--                  />-->
        <!--                  <template #append>-->
        <!--                    <b-button-->
        <!--                      variant="primary"-->
        <!--                      @click="copy(data.vendorSecret)"-->
        <!--                      :disabled="!data.vendorSecret"-->
        <!--                    >-->
        <!--                      <feather-icon icon="CopyIcon" />-->
        <!--                    </b-button>-->
        <!--                  </template>-->
        <!--                </b-input-group>-->
        <!--                <small class="text-danger">{{ errors[0] }}</small>-->
        <!--              </validation-provider>-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--          <b-col sm="6">-->
        <!--            <b-form-group-->
        <!--              :label="$t('field.webhook')"-->
        <!--              label-for="vendorBetWebhook"-->
        <!--            >-->
        <!--              <validation-provider-->
        <!--                #default="{ errors }"-->
        <!--                vid="vendorBetWebhook"-->
        <!--                :name="$t('field.webhook')"-->
        <!--              >-->
        <!--                <b-form-input-->
        <!--                  v-model="data.vendorBetWebhook"-->
        <!--                  :placeholder="$t('field.webhook')"-->
        <!--                  :class="errors.length > 0 ? 'is-invalid' : null"-->
        <!--                  name="vendorBetWebhook"-->
        <!--                />-->
        <!--                <small class="text-danger">{{ errors[0] }}</small>-->
        <!--              </validation-provider>-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1 mr-1"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $t("button.saveChanges") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="resetForm"
            >
              {{ $t("button.reset") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BImg,
  BInputGroup,
  BLink,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import Repository from '@/repositories/RepositoryFactory'
import NSingleImageUploader from '@/components/NSingleImageUploader'
import NCurrencyInput from '@/components/NCurrencyInput'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ProfileRepository = Repository.get('profile')
const AgentRepository = Repository.get('agent')

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BImg,
    BInputGroup,
    NButtonLoading,
    NFormConfirmation,
    NSingleImageUploader,
    BFormDatepicker,
    NCurrencyInput,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: JSON.parse(localStorage.getItem('userData')),
      loading: false,
    }
  },
  methods: {
    submit() {
      this.$refs.updateProfileForm.validate().then(success => {
        if (success) {
          this.loading = true
          ProfileRepository.updateProfile(this.data)
            .then(response => {
              const user = response.data.data
              localStorage.setItem('userData', JSON.stringify(user))
              this.$store.commit('profile/UPDATE_PROFILE', user)
            })
            .catch(error => {
              if (error.response?.data?.message) {
                this.$refs.updateProfileForm.setErrors(
                  error.response?.data?.message,
                )
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    resetForm() {
      this.data = JSON.parse(localStorage.getItem('userData'))

      this.$nextTick(() => {
        this.$refs.updateProfileForm.reset()
      })
    },
    generateCredential() {
      this.loading = true
      AgentRepository.generateCredential()
        .then(response => {
          const { data } = response.data
          this.data.vendorKey = data.key
          this.data.vendorSecret = data.secret
        })
        .catch()
        .then(() => {
          this.loading = false
        })
    },
    copy(value) {
      const clipboardData = event.clipboardData
        || window.clipboardData
        || event.originalEvent?.clipboardData
        || navigator.clipboard

      clipboardData.writeText(value)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('alert.copied'),
          icon: 'BellIcon',
          text: this.$t('alert.copiedToClipboard'),
          variant: 'info',
        },
      })
    },
  },
}
</script>
